<template>
  <v-container />
</template>

<script>
import { mapActions } from 'vuex'
export default {
  data: () => ({
  }),
  created () {
    let dataParams = this.$route.query
    let sessionFilter = {
      params: {
        key: dataParams.key,
        data: dataParams.data
      }
    }
    this.GET_VI_VET_SESSION(sessionFilter).then(
      function (res) {
        sessionStorage.setItem('viVietSession', JSON.stringify(res.data))
        this.$router.push({
          path: '/product-list'
        })
      }.bind(this)
    ).catch(
      function () {
        this.$router.push({
          path: '/lpb-info-invalid'
        })
      }.bind(this)
    )
  },
  methods: {
    ...mapActions([
      'GET_VI_VET_SESSION'
    ])
  }
}
</script>

<style lang="scss" scoped>
</style>
